import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d73face"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "navbar",
  class: "navbar"
}
const _hoisted_2 = { class: "navbar__menu" }
const _hoisted_3 = ["onMouseenter", "onMousemove", "onClick"]
const _hoisted_4 = { class: "navbar__item--link primary-font" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_glitched = _resolveDirective("glitched")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navbarItems, (navbarItem, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["navbar__item", {
          active: navbarItem.active,
          hover: navbarItem.hover,
          clickable: !navbarItem.active,
        }]),
          onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.unhover())),
          onMouseenter: ($event: any) => (_ctx.hover(navbarItem)),
          onMousemove: ($event: any) => (_ctx.hover(navbarItem)),
          onClick: ($event: any) => (_ctx.activate(navbarItem))
        }, [
          _withDirectives(_createElementVNode("p", _hoisted_4, null, 512), [
            [_directive_glitched, _ctx.getNavbarText(navbarItem)]
          ])
        ], 42, _hoisted_3))
      }), 128))
    ])
  ], 512))
}