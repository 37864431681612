
import { NavbarItem, useContentStore } from "@/store/content";
import { useScrollerStore } from "@/store/scroller";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LayoutNavbar",
  computed: {
    ...mapState(useContentStore, [
      "navbarItems",
      "getActive",
      "getHover",
      "getNavbarText",
    ]),
    ...mapState(useScrollerStore, ["isScrolling"]),
  },
  mounted() {
    this.unhover();
    useScrollerStore().addScrollAction(() => {
      this.setNavbarHeight();
    });
  },
  methods: {
    ...mapActions(useContentStore, ["activateNavbarItem", "hoverNavbarItem"]),
    ...mapActions(useScrollerStore, ["scrollTo"]),
    setNavbarHeight() {
      const navbar = this.$refs["navbar"] as HTMLElement;
      const getContentPageFirst =
        document.getElementsByClassName("content__page")[0];
      const { scrollTop } = document.getElementById("app") as HTMLElement;

      if (!navbar || !getContentPageFirst || !scrollTop) {
        return;
      }

      const firstPageBottomRect = getContentPageFirst.getBoundingClientRect();
      const borderWidth = useContentStore().vmin(5);

      if (scrollTop >= innerHeight / 2 - borderWidth) {
        navbar.style.top = borderWidth + "px";
      } else {
        let newTop = (firstPageBottomRect.bottom - scrollTop + borderWidth) / 2;
        navbar.style.top = newTop + "px";
      }
    },
    activate(navbarItem: NavbarItem) {
      if (!navbarItem.active) {
        this.activateNavbarItem(navbarItem, true);
      }
    },
    hover(navbarItem: NavbarItem) {
      if (this.isScrolling || navbarItem.hover) return;
      this.hoverNavbarItem(navbarItem);
    },
    unhover() {
      this.hoverNavbarItem();
    },
  },
});
